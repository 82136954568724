import { Component, EventEmitter, Inject, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { GlobalService } from 'src/app/admin-portal/core/services/global.service';
import { AccountService } from 'src/app/admin-portal/modules/account/services/account.service';
import { ProjectService } from 'src/app/admin-portal/modules/project/services/project.service';
import { whitespaceValidator } from 'src/app/utils/whitespace-validation.utils';

@Component({
  selector: 'app-dialog-edit-project',
  templateUrl: './project-dialog.html',
})
export class ProjectDialogComponent {
  projectForm!: FormGroup;
  isEditMode: boolean = false;
  accountList: any[] = [];
  selected = 'hourly';
  selectedAccount: any;
  selectedCostCentreId: string | undefined;
  errorMessage: string = '';
  isButtonDisabled: boolean = true;
  isSubmitting: boolean = false;
  isNumericInputValid: boolean = true;
  private unsubscribe: Subject<void> = new Subject();
  isMilestoneBased: boolean = false;
  isToggleDisabled: boolean = false;

  constructor(
    public matDialog: MatDialogRef<ProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private projectService: ProjectService,
    private globalService: GlobalService,
    private accountService: AccountService
  ) {}
  @Output() projectEdited: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.isEditMode = this.data.isEditMode;
    this.initializeForm();
    this.loadAccountList().subscribe(() => {
      if (this.isEditMode && this.data.element) {
        this.patchForm(this.data.element);
      }
    });
    this.subscribeToAccountChanges();

    if (this.isEditMode && this.data.element) {
      this.patchForm(this.data.element);
      // Check if milestones array has any values
      const milestones = this.projectForm.get('milestones')?.value;
      if (milestones.length > 0) {
        this.isMilestoneBased = true;
      }
    }

    if (this.isEditMode) {
      this.projectForm
        .get('accountID')
        ?.valueChanges.subscribe((accountId: string) => {
          this.onAccountSelect(accountId);
        });
    }

    this.projectForm.valueChanges.subscribe(() => {
      this.updateButtonState();
    });

    this.updateButtonState();
  }

  patchMilestones(milestones: any[]) {
    const milestonesFormArray = this.milestonesFormArray;
    if (milestonesFormArray) {
      milestonesFormArray.clear();
      milestones.forEach((milestone) => {
        milestonesFormArray.push(
          this.formBuilder.group({
            mileStoneId: [milestone.mileStoneId],
            mileStoneName: [milestone.mileStoneName, Validators.required],
            price: [milestone.price, [Validators.required]],
            dueDate: [milestone.dueDate, Validators.required],
          })
        );
      });
    }
  }

  subscribeToAccountChanges(): void {
    if (this.isEditMode) {
      this.projectForm
        .get('accountID')
        ?.valueChanges.subscribe((accountId: string) => {
          this.onAccountSelect(accountId);
        });
    }
  }

  onTypeChange() {
    this.projectForm.value;
  }

  patchForm(element: any): void {
    this.loadAccountList().subscribe(() => {
      if (this.isEditMode) {
        this.projectService
          .getProjectById(this.data.element.projectId)
          .subscribe((res: any) => {
            this.projectForm.patchValue({
              name: res.data.name,
              costModal: res.data.costModal,
              fixedCost: this.isMilestoneBased
                ? '0'
                : String(res.data.fixedCost),
              projectId: res.data.projectId,
              startDate: res.data.startDate,
              status: this.data.element.status,
              endDate: res.data.endDate,
              accountID: this.data.element.account.accountID,
            });

            const milestonesArray = res.data.mileStones.map((milestone: any) =>
              this.formBuilder.group({
                mileStoneId: [milestone.mileStoneId],
                mileStoneName: [milestone.mileStoneName, Validators.required],
                price: [milestone.price, Validators.required],
                dueDate: [new Date(milestone.dueDate), Validators.required],
              })
            );
            this.isMilestoneBased = milestonesArray.length > 0;
            this.projectForm.get('costModal')?.disable();

            this.projectForm.setControl(
              'milestones',
              this.formBuilder.array(milestonesArray)
            );

            if (this.selectedAccount) {
              this.projectForm.patchValue({
                cost_centerID: res.data.costCenter.cost_centerID,
              });
            }

            if (res.data.costModal === 'fixed') {
              this.selected = 'fixed';
            }
          });
      }
      this.subscribeToAccountChanges();
    });
  }

  endDateValidator(startDateControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const endDate = control.value;
      const startDate = control.parent?.get(startDateControlName)?.value;

      if (startDate && endDate && endDate < startDate) {
        return { reviewDateInvalid: true };
      }
      return null;
    };
  }

  updateToggleButtonState(): void {
    const milestonesArray = this.projectForm.get('milestones') as FormArray;
    this.isToggleDisabled = milestonesArray.length > 0;
  }

  toggleMilestoneBased() {
    if (this.isMilestoneBased) {
      // Handle turning off the milestone-based option
      const milestonesArray = this.milestonesFormArray;

      if (milestonesArray && milestonesArray.length === 1) {
        const milestoneId = milestonesArray.at(0).get('mileStoneId')?.value;

        // Call the delete API for the last milestone
        this.projectService.deleteMileStones(milestoneId).subscribe({
          next: () => {
            this.clearMilestones();
            this.isMilestoneBased = false;
            this.updateButtonState();
          },
          error: (error: any) => {
            console.error('Error deleting milestone:', error);
            this.clearMilestones();
            this.isMilestoneBased = false;
          },
        });
      } else {
        this.clearMilestones();
        this.isMilestoneBased = false;
      }
    } else {
      // Handle turning on the milestone-based option
      this.isMilestoneBased = true;
      if (!this.milestonesFormArray?.length) {
        this.addMilestone();
      }
    }

    this.milestonesFormArray?.controls.forEach((control: AbstractControl) => {
      control.get('mileStoneName')?.updateValueAndValidity();
      control.get('price')?.updateValueAndValidity();
      control.get('dueDate')?.updateValueAndValidity();
    });

    this.updateButtonState();
  }

  updateButtonState() {
    this.isButtonDisabled = !this.projectForm.valid || !this.projectForm.dirty;
  }

  clearMilestones(): void {
    const milestonesArray = this.milestonesFormArray;
    if (milestonesArray) {
      milestonesArray.clear();
      this.projectForm.markAsDirty();
    }
  }

  get milestonesFormArray(): FormArray | null {
    return this.projectForm.get('milestones') as FormArray | null;
  }

  addMilestone(milestoneId: string = ''): void {
    const milestonesArray = this.milestonesFormArray;
    if (milestonesArray) {
      milestonesArray.push(this.createMilestoneFormGroup(milestoneId));
      this.projectForm.markAsDirty();
    }
  }

  removeMilestone(index: number): void {
    const milestonesArray = this.milestonesFormArray;
    if (milestonesArray && milestonesArray.length > index) {
      const milestoneId = milestonesArray.at(index).get('mileStoneId')?.value;
      this.projectService.deleteMileStones(milestoneId).subscribe({
        next: () => {
          milestonesArray.removeAt(index);
          this.projectForm.markAsDirty();
          this.updateButtonState();
        },
        error: (error: any) => {
          console.error('Error deleting milestone:', error);
        },
      });
    }
  }

  createMilestoneFormGroup(milestoneId: string = ''): FormGroup {
    const milestoneFormGroup = this.formBuilder.group({
      mileStoneId: [milestoneId],
      mileStoneName: ['', Validators.required],
      price: ['', Validators.required],
      dueDate: ['', Validators.required],
    });

    this.projectForm.get('fixedCost')?.valueChanges.subscribe(() => {
      milestoneFormGroup.get('price')?.updateValueAndValidity();
    });

    milestoneFormGroup.valueChanges.subscribe(() => {
      this.projectForm.markAsDirty();
      this.updateButtonState();
    });

    return milestoneFormGroup;
  }

  initializeForm(initialType: string = 'fixed'): void {
    this.projectForm = this.formBuilder.group({
      name: ['', [Validators.required, whitespaceValidator()]],
      costModal: [initialType, Validators.required],
      fixedCost: [''],
      status: ['active', Validators.required],
      startDate: ['', Validators.required],
      endDate: [null, [this.endDateValidator('startDate')]],
      cost_centerID: ['', Validators.required],
      accountID: ['', Validators.required],
      projectId: [''],
      milestones: this.formBuilder.array([]),
    });

    if (this.isEditMode) {
      this.projectForm.get('accountID')?.disable();
      this.projectForm.addControl('projectId', this.formBuilder.control(''));
    }
  }

  loadAccountList(): Observable<any> {
    const MAX_LIMIT = 9999;
    return this.accountService
      .getAccountList(0, MAX_LIMIT, '', 'createdAt', 'ASC')
      .pipe(
        tap((response: any) => {
          this.accountList = response.data.records;
        })
      );
  }

  onSubmit(): void {
    const projectData = this.projectForm.value;

    if (this.projectForm.valid && !this.isSubmitting) {
      this.isSubmitting = true;

      const costModalControl = this.projectForm.get('costModal');
      if (costModalControl?.disabled) {
        costModalControl.enable();
      }

      const {
        name,
        costModal,
        fixedCost,
        status,
        startDate,
        endDate,
        accountID, // Account ID is always required
      } = this.projectForm.value;
      const projectId = this.projectForm.get('projectId')?.value;
      const isMilestoneBased = costModal === 'fixed' && this.isMilestoneBased;
      let projectPayload: any = {
        name,
        costModal,
        status,
        startDate,
        endDate,
        accountID,
        projectId,
        isMileStone: isMilestoneBased,
      };

      if (costModal === 'fixed') {
        const milestones = projectData.milestones.map((milestone: any) => ({
          ...milestone,
          mileStoneId: milestone.mileStoneId || null,
        }));
        projectPayload = {
          ...projectPayload,
          fixedCost: isMilestoneBased ? '0' : String(fixedCost),
          mileStones: milestones,
          isMileStone: this.isMilestoneBased,
        };
      }

      // Check if cost center is selected
      const cost_centerID = this.projectForm.get('cost_centerID')?.value;
      if (cost_centerID) {
        projectPayload = {
          ...projectPayload,
          cost_centerID,
        };
      }

      // If in edit mode, update the project
      const request$ = this.isEditMode
        ? this.projectService.updateProject({ ...projectPayload, projectId })
        : this.projectService.createProject(projectPayload);

      request$
        .pipe(takeUntil(this.globalService.componentDestroyed(this)))
        .subscribe({
          next: () => {
            this.isSubmitting = false;
            this.matDialog.close({ success: true });
          },
          error: (error: any) => {
            console.error(
              `Error ${this.isEditMode ? 'updating' : 'creating'} project:`,
              error
            );
            this.isSubmitting = false;
          },
        });

      // Re-disable the costModal control after submission
      if (this.isEditMode) {
        costModalControl?.disable();
      }
    } else {
      console.error('Invalid form data.');
    }
  }

  onSubmissionSuccess(): void {
    this.isSubmitting = false;
    this.projectEdited.emit();
    this.matDialog.close({ success: true });
  }

  onSubmissionError(error: any): void {
    this.isSubmitting = false;
    console.error('Error submitting form:', error);
  }

  onAccountSelect(accountId: string): void {
    this.selectedAccount = this.accountList.find(
      (account) => account.accountID === accountId
    );

    if (this.selectedAccount) {
      this.errorMessage = '';
    } else {
      console.error('Selected account not found in account list.');
    }
  }

  logSelectedCostCentre(selectedCostCentreId: string): void {
    this.selectedCostCentreId = selectedCostCentreId;
  }

  numericInputValidity: { [key: string]: boolean } = {
    fixedCost: true,
    price: true,
  };

  handleNumericInputValidityChange(field: string, validity: boolean) {
    this.numericInputValidity[field] = validity;
  }

  areAllNumericInputsValid(): boolean {
    return Object.values(this.numericInputValidity).every((valid) => valid);
  }

  close() {
    this.matDialog.close();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
